import * as React from 'react';
import { Helmet } from 'react-helmet';
import FxLayout from '../components/shared/layout/layout';
import { Link } from 'gatsby';

interface I404PageState {
  message: string;
}

interface I404PageProps {
  pageContext: any;
}

export default class PageNotFoundPage extends React.Component<I404PageProps, I404PageState> {
  constructor(props: any) {
    super(props);
  }

  render() {
    const pageContext = this.props.pageContext;
    return (
      <FxLayout sitemap={pageContext.sitemap}>
        <Helmet>
            <title>404 - {process.env.SITE_TITLE}</title>
          </Helmet>
        <div className="fx-l-404">
          <div className="fx-container">
            <div className="fx-layout">
              <div className="fx-layout-col-mobile--24">
                <div className="fx-l-404-body">
                  <h1 className="fx-l-404-body-header">404 - Page not found</h1>
                  <p className="fx-l-404-body-message">Sorry, the requested page doesn't exist or has been removed.</p>
                  <p className="fx-l-404-body-message">Click the button below to go back home.</p>
                  <Link to="/home/" className="fx-l-404-body-button fx-btn fx-btn--primary-outlined">
                    Go to home
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </FxLayout>
    );
  }
}
